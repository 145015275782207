import React from 'react';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

import { getStaticCDN } from '@design-system/utils/utils';
import { ENV_URL } from '@source/constants/common';
import { ICustomerReview, ICustomerReviewContent } from '@source/components/CustomerReview';
import { NEXT_PUBLIC_WEB_CR } from '@source/constants/env';

dayjs.extend(relativeTime);

const CUSTOMER_REVIEW_SG: ICustomerReview[] = [
  {
    avatar: getStaticCDN('/assets/shared/user-reviews/sg/fairuz-ramlee.webp'),
    name: 'Fairuz Ramlee',
    star: 5,
    review:
      'Definitely the go to company if you wanna purchase your cars. With such a proper structure and solid set of staff, it was a very pleasant experience buying our car with them, especially with the awesome 5 star service rendered by Jonathan Lee. From the moment we walked in, we felt welcome and we felt it throughout the whole buying process. All queries were answered by Jonathan promptly and professionally, and he made us felt at ease throughout. 5 stars for Jonathan and the team. Will definitely recommend to those who wants to buy their car go to Carro.',
  },
  {
    avatar: getStaticCDN('/assets/shared/user-reviews/sg/bruno-low.webp'),
    name: 'Bruno Low',
    star: 5,
    review:
      'I came to Carro to initially sell my car. the price excellent, after discussing online with the staff, i have shortlisted few cars for trade in. Jonathan lee was helping us throughout the process providing good advice and never push for the most costly option. he made us try all the cars and finally got the one that fit our family needs. will definitely recommend Jonathan lee who did a good job',
  },
  // {
  //   avatar: getStaticCDN('/assets/shared/user-reviews/sg/arshad-shahidah.webp'),
  //   name: 'Arshad Shahidah Arifah Adil',
  //   star: 5,
  //   review:
  //     'Karen Goh assisted myself in trading in my car and buying of a 3+ year old car. She was patient and attentive to our needs. Truly wonderful service. I also like the 160 point check that they did and repainting was done to remove all the scratches etc. The car was also well polished both interior and exterior prior to handover. Karen was kind enough to arrange for the transfer of my front and back camera without any charges. Being Mr Sceptical, I went to my regular workshop in Jb one day after collecting the car to check on the condition of the car and it was really in good condition, even the Hybrid battery who might have cost me a lot of money to replace if it have been faulty. I also like that they are confident enough of their car to provided 1 year warranty for engine and gear box. Thanks Karen and Carro. From a really satisfied customer.',
  // },
  {
    avatar: getStaticCDN('/assets/shared/user-reviews/sg/raymond-chen.webp'),
    name: 'Raymond Chen',
    star: 5,
    review:
      'Jonathan is definitely the best person to go to if you are looking to purchase a car. Positive and cheerful attitude sure lighten up the stress when choosing my 1st ride. He followed through the whole process of my purchase with constant updates of information. True gem of Carro, will definitely spread the words of his awesome service!!! Thanks bro!!',
  },
  {
    avatar: getStaticCDN('/assets/shared/user-reviews/sg/hazeeq-samsuri.webp'),
    name: 'Hazeeq Samsuri',
    star: 5,
    review:
      'Smooth and fuss free transaction to buy a car from Carro. Zero admin fees and no hidden fees unlike traditional car dealers. Was served by Jonathan and he has been very proactive in updating the car status and being honest in pointing out the defects of the car. Thank you again Jonathan for expediting the handover slightly earlier than planned!',
  },
  {
    avatar: getStaticCDN('/assets/shared/user-reviews/sg/世坤馮.webp'),
    name: '世坤馮',
    star: 5,
    review:
      'We all know how dark and dirty the 2nd hand car dealer industry is, so i went in to Carro being very skeptical of the car i was interested in. I was assigned to Jon, who was really honest, frank and also professional. He was really accomodating with my requests, but also really frank when they are too overboard. While i was away during my business trip, Jon constantly gave me updates. The day before the collection, jon even reminded me to ensure that i increased my daily spending limit to minimise any hiccups during the collection itself. Even after the collection, he has been super helpful in getting me settled in to my new ride. Id recommend jon and carro to anyone who is looking for a seamless, friendly and honest service!',
  },
  {
    avatar: getStaticCDN('/assets/shared/user-reviews/sg/timothy-paul-xie.webp'),
    name: 'Timothy Paul Xie',
    star: 5,
    review:
      'Dominic was an absolute pleasure to deal with. He went the extra mile in helping me with getting a great deal for the car, and was extremely insightful and meticulous throughout the whole deal process. It was a smooth transaction and since having the car for 7 months I have not had any issues. Should I consider changing my vehicle in the future - Carro, together with Dominic, would most certainly be my choice! 😁',
  },
  {
    avatar: getStaticCDN('/assets/shared/user-reviews/sg/ryan-marc.webp'),
    name: 'Ryan Marc',
    star: 5,
    review:
      "sold my car at Carro recently, very professional and friendly staff. fast, convenient, good price. definitely will recommend / head back to them if I'm selling or buying. good job Carro 👍 (staff to thank: Cody - main person I dealt with & Raymond)",
  },
  {
    avatar: getStaticCDN('/assets/shared/user-reviews/sg/calvin-ennovyevoli.webp'),
    name: 'Calvin Ennovyevoli',
    star: 5,
    review:
      'This is my first time purchasing car from Carro..i enjoyed the whole process, no hidden charges, everything explained nicely from Mr Jonathan, which was the sales liaising with me. Service was excellent, due to my work schedule, he was able to accommodate to my timing and location for handover my old car and takeover my new car..very prompt in replying my enquiries even after his working hours. After sales service was excellent too! Definitely will look for him again if i want to change car👍👍',
  },
  {
    avatar: getStaticCDN('/assets/shared/user-reviews/sg/edmund-soh.webp'),
    name: 'Edmund Soh',
    star: 5,
    review:
      "Dominic is knowledgeable & patient and takes time to answer all our questions ! Feels safe & comfortable dealing with Carro & Dominic especially when the 2nd hand car market has quite a bad reputation. Aftersales service didn't disappoint too !  Dominic is open to help with any questions or issues we have about the car post sales  Go to Carro & look for Dominic ! No regrets to deal with them ",
  },
  {
    avatar: getStaticCDN('/assets/shared/user-reviews/sg/james-chia.webp'),
    name: 'James Chia',
    star: 5,
    review:
      'Great service provided by Jonathan. He was very patient and answered every question that was asked. Extremely easy person to talk to. Felt like an old time friend yet still maintained that professionalism. Went the extra mile to handover the car in double quick time. Even went back to handover on his off day! 10/10 will recommend! 😇',
  },
  {
    avatar: getStaticCDN('/assets/shared/user-reviews/sg/louies-tan.webp'),
    name: 'Louies Tan',
    star: 5,
    review:
      'Purchased a car from a sales rep named Jonathan... Received an outstanding service from him, ranging from being attentive to your needs, to meticulously taking note of the issues/requests, Jon had totally aced all aspects as an experience salesperson. In fact, on the day of collection, he even took effort to ensure that the vehicle is sanitised properly (bcos I have a 2-yr old kid to pick up after that)! My recommendation - definitely worthy of a visit to view your preferred vehicles with him and have a honest and upfront discussion with him. No hidden agendas, no ballyhoos.... Jonathan is da man!',
  },
  {
    avatar: getStaticCDN('/assets/shared/user-reviews/sg/kelvin-ang.webp'),
    name: 'Kelvin Ang',
    star: 5,
    review:
      "Truly enjoyed the process of purchasing a second hand vehicle from Carro. It's definitely refreshing to step away from the typical, traditional second hand car dealers in Singapore, the ones with the hidden charges, in-house loans for the max term avail., etc. It feels great talking to someone who is informative, direct and most importantly open. To me, Carro made buying a second hand car frustration free and driving away feeling assured. Wish more dealers are like this. Thank you, Jonathan (Lee).",
  },
  {
    avatar: getStaticCDN('/assets/shared/user-reviews/sg/yeeling-yeong.webp'),
    name: 'Yee Ling Yeong',
    star: 5,
    review:
      'Was introduced by a friend to contact Carro and we had a good and hassle free experience with him. No hard selling, very responsive to our queries, before, during and after transaction. Would recommend Carro and look for Jonathan!',
  },
  {
    avatar: getStaticCDN('/assets/shared/user-reviews/sg/siti-mijaya.webp'),
    name: 'Siti Mijaya',
    star: 5,
    review:
      'The salesperson that assisted my husband and I is Dominic. He is one of the most helpful sales personnel i had ever met! I bombarded him with so many questions and he was nothing but patient. First, he takes the time to fully understands what we are looking for before recommending and showing us some of the cars available at Carro. He had many good tips for us as well. Extremely easy to talk to, no upselling, humourous yet maintains professionalism. Appreciate all the time you’ve spent with us Dominic! Keep up the good job. Will defo be recommending friends to you!! 👏🏼👍🏼h',
  },
  {
    avatar: getStaticCDN('/assets/shared/user-reviews/sg/jiangbin-sun.webp'),
    name: 'Jiangbin Sun',
    star: 5,
    review:
      'Definitely recommend! Carro is a quite good one stop shop for first time buyers like us. Jonathan really helped us a lot from all aspects - introducing the car, sharing the whole process, recommending on insurance and loan. He is knowledgeable and willing to help always. Great experience!',
  },
  {
    avatar: getStaticCDN('/assets/shared/user-reviews/sg/jean-ong.webp'),
    name: 'Jean Ong',
    star: 5,
    review:
      'Compliment for Jonathan. When we first visited Carro, we weren’t expect it would become the best car dealer company we have ever visited. We were warmly welcomed by Jonathan (sales representative), he was young and we thought he was a fresh grad. Little did we expect his vast knowledge about car models and car sales immediately drew our attention. He shared that he has been in car sales Industry for over 12 years. Jonathan was not like any sales we met before, his humour, helpful and casual personality made us felt like friends. He was sincere and shared his knowledge about car models we inquired patiently. He was truthful and no hard-sell, no pressure, he addressed all our concerns effortlessly. He made car transaction so simplified and fuss free. Jonathan has delivered a complete seamless transaction we could ever imagined, he direct offered us the best deal on purchase and trade-in without needing wasting time on draggy bargaining. He offered us new car accessories (customised car mat, car cam front and back, original car plate frame, etc) which added more perks to our purchase. From trading in, purchasing, loan, insurance, retaining car plate nbr, all paperwork, and ensuring finest condition of the car before handover. Every detail was well taken care by him. In addition, Jonathan will continue attending to all our queries swiftly after deal clinched (giving us assurance and a peace of mind). We were glad we made the right choice to Carro and meeting such exemplary sales - Jonathan, who has delivered a truly remarkable car purchasing experience to us. We highly recommend anyone who plans to purchase car to look for Jonathan @ Carro 👍🏻🤩 Reference: SMW5193T BMW 118i',
  },
  {
    avatar: getStaticCDN('/assets/shared/user-reviews/sg/vick-vick.webp'),
    name: 'Vick Vick',
    star: 5,
    review:
      'Jonathan is very helpful in the way he explaining the vehicle to me. Im very grateful for his assistance over here at carro. Together with Magdalene, she assisted in the rest of the admin works and im pleased to be served by them',
  },
  {
    avatar: getStaticCDN('/assets/shared/user-reviews/sg/ultraman-azli.webp'),
    name: 'Ultraman Azli',
    star: 5,
    review:
      'A big thank you to Zeo and Magdeline. They are able to portray great expertise in their job field, very initiative and dedicated. Helpful and fast response. Helped me throughout my buying journey. The condition of the car was great and I’m in no regret dealing with Carro.  Thank you, Carro team for the excellent service. 👍🏼',
  },
  {
    avatar: getStaticCDN('/assets/shared/user-reviews/sg/diana-tan.webp'),
    name: 'Diana Tan',
    star: 5,
    review:
      'Visited Carro with my brother for his first car and was served by Jonathan. He showed great patience while addressing to our many queries. We eventually managed to find a suitable ride with the excellent recommendations and advice by Jonathan. Definitely will go back to him for our future car purchases and also recommend to our family and friends!',
  },
  {
    avatar: getStaticCDN('/assets/shared/user-reviews/sg/desmond-low.webp'),
    name: 'Desmond Low',
    star: 5,
    review:
      'We were attended to by Jonathan. He was very professional and tried his best to answer all our queries and made sure to meet our requirements and needs as first time buyers. He was professional and we never felt that he was just transacting a sale, but rather, giving us invaluable advice and suggestions. He made additional effort to ensure that our purchase experiences were a positive one. Thank you Jonathan!',
  },
  {
    avatar: getStaticCDN('/assets/shared/user-reviews/sg/charles-chia.webp'),
    name: 'Charles Chia',
    star: 5,
    review:
      'Jonathan has been a great help in assisting us in getting our first car. Very honest, very knowledgeable and very candid. We are very happy with the purchase and also all the services rendered. Great service from Jonathan, great experience with Carro! 🙂',
  },
  {
    avatar: getStaticCDN('/assets/shared/user-reviews/sg/arran-tan.webp'),
    name: 'Arran Tan',
    star: 5,
    review:
      'Was interested in the BMW 118i and was served Karen Goh on 24 Jul 2022. She approached with a friendly attitude and had much eagerness to assist our demands and answered our questions professionally. A joy to have served by her. Thank you Karen!',
  },
  {
    avatar: getStaticCDN('/assets/shared/user-reviews/sg/deniel-ong.webp'),
    name: 'Deniel Ong',
    star: 5,
    review:
      'Top service, right from the admin who contacted me and arranged for an appointment, to the salesperson Jonathan who served us with sincerity. No hidden cost and even going the extra mile to resolve any potential hiccups so as to ensure a hassle free handover. Highly recommend Carro and Jonathan to all. Thanks！',
  },
  {
    avatar: getStaticCDN('/assets/shared/user-reviews/sg/raymund-francis.webp'),
    name: 'Raymund Francis',
    star: 5,
    review:
      "I am a first-time client of Carro. I was planning to trade in my car. I was offered a 'decent' amount at the recent Car Show 2022 but was later told that it was an overtrade thing. Other car dealers offered me $20k less. However, one car dealer advised me to try Carro for a better quote. I did and, boy!, was I pleasantly surprised. I was offered much more than any other dealer, subject to a physical check. The check by Dixon Lee, confirmed my car's good condition, and I was given the promised amount without any fuss. I strongly recommend Carro! I also commend Carro's ambassador, Dixon Lee, for a job well done!",
  },
  {
    avatar: getStaticCDN('/assets/shared/user-reviews/sg/jake-tan.webp'),
    name: 'Jake Tan',
    star: 5,
    review:
      'Just had my first experience of purchasing a car experience at Carro with Jonathan! Jonathan was an absolute pleasure to deal with. He very helpful and went the extra mile to make sure I was well sorted and informed. Thank you for the excellent service and help provided through the extremely quick and efficient process to ownership. Very happy buyer and 10/10 will come back to Carro because of Jonathan. 🤝',
  },
  {
    avatar: getStaticCDN('/assets/shared/user-reviews/sg/huiLing-ng.webp'),
    name: 'HuiLing Ng',
    star: 5,
    review:
      'With Carro Singapore, sale of car is no longer a hassle. No gimmicks, no hidden extra charges. However, please take note there is a cost ($30) to be borne by the seller if you choose to have your existing car collected from your home carpark. This is reasonable since it has to be mutually agreed between the seller and Carro Singapore and will be stated clearly in the S&P agreement. After submitting your car ownership details to Carro Singapore, their staff will come back to you with a quoted amount (within 24 hours) for your consideration on whether you would like to proceed with viewing. The quoted amount is subjected to confirmation upon their staff’s assessment on the actual condition of the car after viewing. You would then have a gauge of where you are. If you are satisfied with the quoted amount confirmed by their staff upon viewing, their staff will prepare a S&P agreement for you to sign on the spot. Depending on your availability and agreement with Carro Singapore, the handover date is flexible. To conclude this review, I would say that my entire experience with Carro Singapore is excellent. The transaction was a breeze for first timers like us selling on our own. Dixon (the sales coordinator assigned by Carro) and Shaharul (the handover coordinator assigned by Carro) are very helpful, patient and nice people. Well done, Carro Singapore! 👍🏻🥳😊',
  },
  {
    avatar: getStaticCDN('/assets/shared/user-reviews/sg/leo-koh.webp'),
    name: 'Leo Koh',
    star: 5,
    review:
      'We came across Carro while researching on where to purchase a car and indeed they stood true to the many splendid reviews with a high level of professionalism and impeccable service. My wife and I had a great experience with Jonathan, he was very friendly and helpful, and prepared all the necessary paperwork and transactions promptly. It was really a fuss-free experience purchasing from Carro. Will definitely recommend Carro!',
  },
];

const CUSTOMER_REVIEW_ID: ICustomerReview[] = [
  {
    avatar: getStaticCDN('/static/img/homeID/customer-icon/trisnawati-utama.png'),
    name: 'Trisnawati Utama',
    star: 5,
    review:
      'Tempat cari mobil bekas yang berkelas, showroomnya nyaman, terjamin baik berkasnya dan barangnya keceh lah sukses terus carro',
    type: 'google',
    time: dayjs('2021-04-1').fromNow(),
  },
  {
    avatar: getStaticCDN('/static/img/homeID/customer-icon/customer-avatar.svg'),
    name: 'Adianto Cahyono',
    star: 5,
    review:
      'Pelayanannya sangat memuaskan, unit yang dijual di showroom ini juga diinpeksi secara khusus dengan tenaga kerja yang profesional.',
    type: 'google',
    time: dayjs('2021-03-21').fromNow(),
  },
  {
    avatar: getStaticCDN('/static/img/homeID/customer-icon/customer-avatar.svg'),
    name: 'Fikri Mochammad',
    star: 5,
    review:
      'Tempat membeli mobil bekas yang transparan terkait kondisi mesin, eksterior dan interiornya. Tersedia macam² mobil yg bisa di liat langsung ke lokasi maupun di websitenya. Marketingnya profesional dan ramah, bisa dihubungi namanya Mas widy. Sangat rekomen buat yg lagi nyari mobil bekas.',
    type: 'google',
    time: dayjs('2021-03-21').fromNow(),
  },
  {
    avatar: getStaticCDN('/static/img/homeID/customer-icon/denny-ariv.png'),
    name: 'Denny Ariev',
    star: 5,
    review:
      'Cozy and best place to picked used car surrounding Bekasi. Various type and unit in huge area. All unit already verified and checked by expert technician. Strong in customer service, they will guide you along the selection process and after sales service.',
    type: 'google',
    time: dayjs('2020-10-20').fromNow(),
  },
  {
    avatar: getStaticCDN('/static/img/homeID/customer-icon/hari-setiawan.png'),
    name: 'Hari Setiawan',
    star: 5,
    review:
      'Pengen jual/tukar tambah kendaraan anda hanya di carro automall bersertifikasi mari kunjungi showroom mobil seken berkualitas',
    type: 'google',
    time: dayjs('2021-03-21').fromNow(),
  },
  {
    avatar: getStaticCDN('/static/img/homeID/customer-icon/customer-avatar.svg'),
    name: 'Richard Kristanto',
    star: 5,
    review:
      'Mas dede sangat membantu dan informatif tentang mobil yang ingin kami beli. Tentu kami akan merekomendasikan kepada orang lain! Kerja bagus Carro!',
    type: 'google',
    time: dayjs('2021-02-21').fromNow(),
  },
  {
    avatar: getStaticCDN('/static/img/homeID/customer-icon/doni-saputro.png'),
    name: 'Doni Saputro',
    star: 5,
    review:
      'Unit mobilnya banyak dan barangnya masih gres seperti baru plus dapet jaminan mesin dan cat segala lagi...mantul Carro Automall',
    type: 'google',
    time: dayjs('2021-02-21').fromNow(),
  },
  {
    avatar: getStaticCDN('/static/img/homeID/customer-icon/nanang-kurniawan.png'),
    name: 'Nanang Kurniawan',
    star: 5,
    review: 'Tempat yg nyaman utk melakukan jual beli, pelayanan yg ramah bikin betah.',
    type: 'google',
    time: dayjs('2021-02-21').fromNow(),
  },
  {
    avatar: getStaticCDN('/static/img/homeID/customer-icon/ibu-mega-agung.png'),
    name: 'Ibu Mega Agung',
    star: 5,
    review:
      'Klo mau Jual Mobil bekas anda di sini ok banget, harga yg di tawarkan sesuai harapan pemilik. Mau Beli Mobil bekas juga di sisi tempatnya, tidak khawatir dgn kondisi mobil nya, karna dijamin bagus dan bergaransi.',
    type: 'google',
    time: dayjs('2020-10-21').fromNow(),
  },
  {
    avatar: getStaticCDN('/static/img/homeID/customer-icon/shifairoh-tanti.png'),
    name: 'Shifairoh Tanti',
    star: 5,
    review:
      'Seneng sih semua pegawainya ramah, segala yang aku tanyakan juga dijawab dan diberi arahan dengan baik. Kalau bisa kasih bintang lebih dari 5 auto kasih deh. Pertahankan terus ya',
    type: 'google',
    time: dayjs('2020-10-21').fromNow(),
  },
];

const CUSTOMER_REVIEW_TH: ICustomerReview[] = [
  {
    avatar: getStaticCDN('/assets/th/customer-review-profile/Bunyarat.jpeg'),
    name: 'Bunyarat Kansunin',
    star: 5,
    review:
      'จะขายรถยนต์นะ ให้นึกถึงที่นี่เลยค่ะ Carro Thailand เป็นเว็บไซต์ที่มืออาชีพมากๆ จัดการให้เราขายได้รวดเร็วทันใช้เงินดีมากๆ',
    type: 'facebook',
    time: dayjs('2021-05-23').fromNow(),
  },
  {
    avatar: getStaticCDN('/assets/th/customer-review-profile/customer-avatar.svg'),
    name: 'ภูเบศ ทองเมืองสุข',
    star: 5,
    review:
      'เป็นเพจที่เชื่อถือได้มากๆ บริการได้ดีมากๆ ราคาทำให้เราพอใจเลยนะ ยิ่งที่สำคัญอยากขายด่วนๆ เขาก็จัดให้ได้เลย',
    type: 'facebook',
    time: dayjs('2021-05-18').fromNow(),
  },
  {
    avatar: getStaticCDN('/assets/th/customer-review-profile/Paritda.jpeg'),
    name: 'Paritda Buakhamsang',
    star: 5,
    review: 'รถถูกดี พึ่งไปเทสไดร์ฟมา โอเคเลย คอนเฟิร์มรับรถละ',
    type: 'facebook',
    time: dayjs('2021-05-23').fromNow(),
  },
  {
    avatar: getStaticCDN('/assets/th/customer-review-profile/Sriyakorn.jpeg'),
    name: 'Sriyakorn Yuwawej',
    star: 5,
    review:
      'เป็นอะไรที่สะดวกสบายที่สุดแล้วคะ เราตัวคนเดียวไม่กล้าไปตามเต็นส์ เลยลองลงในcarro ดู ประทับใจมากคะ ทำเรื่องขายรถซึ่งเป็นยากๆของเรา ให้เป็นเรื่องง่ายๆ พี่ๆที่มาดูรถก็น่ารักมากๆคะ ให้คำแนะนำดีมากๆ',
    type: 'facebook',
    time: dayjs('2021-05-14').fromNow(),
  },
  {
    avatar: getStaticCDN('/assets/th/customer-review-profile/Pongyot.jpeg'),
    name: 'Pongyot Wansanid',
    star: 5,
    review: 'ชอบครับ มีการอัพเดตแจ้งสถานะให้เราทราบตลอด เจ้าหน้าที่ติดต่อมาเร็ว ตกลงราคาพอใจ จบก็โอนเงินให้เร็วดี',
    type: 'facebook',
    time: dayjs('2021-05-08').fromNow(),
  },
  {
    avatar: getStaticCDN('/assets/th/customer-review-profile/Tony.jpeg'),
    name: 'Tony Pee',
    star: 5,
    review:
      'ตอนแรกก็กังวลจะขายออกยาก พอได้ลองลงรายละเอียดไปเจ้าหน้าที่ติดตอกลับประสานงานต่างๆเร็วมากครับ ขายออกได้เร็วจริงๆ',
    type: 'facebook',
    time: dayjs('2021-05-07').fromNow(),
  },
  {
    avatar: getStaticCDN('/assets/th/customer-review-profile/Adisak.jpeg'),
    name: 'Adisak Pattarachit',
    star: 5,
    review:
      'ทีแรกคิดว่าขั้นตอนจะยุ่งยาก เอาเข้าจริงๆง่ายมากๆครับ กรอกรายละเอียดให้ เดี๊ยวมีเจ้าหน้าที่ติดต่อมา แล้วก็นัดเข้ามาดูรถ จบไปแบบ งงๆ เงินก็เข้าเร็วดีนะ ลองดูครับง่ายจริง',
    type: 'facebook',
    time: dayjs('2021-05-01').fromNow(),
  },
  {
    avatar: getStaticCDN('/assets/th/customer-review-profile/Sutanon.jpeg'),
    name: 'Sutanon Potchara',
    star: 5,
    review:
      'เป็นการขายรถมือสองที่สะดวกและเร็วที่สุดเลยจ้า จบเร็ว เงินเข้าเร็วไม่ต้องรอให้เสียเวลาเลย ชมเจ้าหน้าที่ช่วยเหลือแนะนำข้อมูลต่างๆชัดเจนครบถ้วนดีค่ะ',
    type: 'facebook',
    time: dayjs('2021-04-26').fromNow(),
  },
  {
    avatar: getStaticCDN('/assets/th/customer-review-profile/Sakolwit.jpeg'),
    name: 'Sakolwit Sriboonruang',
    star: 5,
    review:
      'ท่านใดสนใจอยากขายรถแนะนำเว็บนี้เลยครับ วันเดียวรู้เรื่องเลย ได้ราคาตามที่เราต้องการ Admin ประสานงานให้ดีมากๆๆ วันเดียวจบรับเงินเลย สุดยอดมากเลย.',
    type: 'facebook',
    time: dayjs('2021-04-08').fromNow(),
  },
  {
    avatar: getStaticCDN('/assets/th/customer-review-profile/Argento.jpeg'),
    name: 'Argento Dion',
    star: 5,
    review:
      'เป็นแอพพลิเคชั่นที่ใช้งานง่าย สะดวก เป็นแอพพลิเคชั่นที่น่าใช้ในการขายรถมากครับ มีพนักงานบริการที่ว่องไว ให้ข้อมูลครบถ้วน สะดวกในการซื้อขายรถมากครับ ประทับใจมากสำหรับการให้บริการครับ 🥰🥰',
    type: 'facebook',
    time: dayjs('2021-03-24').fromNow(),
  },
  {
    avatar: getStaticCDN('/assets/th/customer-review-profile/Wuttichai.jpeg'),
    name: 'Wuttichai Thongtanom ',
    star: 5,
    review:
      'บริการที่ Carro ดีมากๆครับ ใส่ใจลูกค้า มีความเป็นกันเอง รวดเร็ว สะดวกสบาย อยากแนะนำทุกท่านให้มาใช้บริการครับ 👍😍',
    type: 'facebook',
    time: dayjs('2021-03-24').fromNow(),
  },
  {
    avatar: getStaticCDN('/assets/th/customer-review-profile/chanin.jpeg'),
    name: 'Cha Nin',
    star: 5,
    review:
      'พนักงานบริการดีครับ สุภาพ บอกรายละเอียดเกี่ยวกับสินค้าและขั้นตอนการซื้อสินค้าอย่างเข้าใจ สำหรับผม ชอบมากครับ การันตี เชื่อถือได้จริงครับ 100% มีลุกบอกลุก มีหลายบอกหลาย',
    type: 'facebook',
    time: dayjs('2021-10-21').fromNow(),
  },
  {
    avatar: getStaticCDN('/assets/th/customer-review-profile/babyple.jpeg'),
    name: "ไอ้เจ้า' เบบี้เปิ้ล",
    star: 5,
    review: 'บริการดีมาก บริการไวมากครับ',
    type: 'facebook',
    time: dayjs('2021-10-20').fromNow(),
  },
  {
    avatar: getStaticCDN('/assets/th/customer-review-profile/kritsana.jpeg'),
    name: 'Kritsana Kotwong',
    star: 5,
    review:
      'เรื่อง ซื้อ-ขายรถยนต์ สภาพดี ราคาสมเหตุผล ต้องที่ Carro Thailind ที่เดียวครบ จบทุกปัญหา บริการดีมาก เข้าใจการซื้อ-ขายรถยนต์ ได้ทันทีแม้จะซื้อ-ขายครั้งแรก ⭐⭐⭐⭐⭐',
    type: 'facebook',
    time: dayjs('2021-10-20').fromNow(),
  },
  {
    avatar: getStaticCDN('/assets/th/customer-review-profile/lovelove.jpeg'),
    name: 'รักรัก จิงจิง',
    star: 5,
    review: 'ใช้งานดี เรียบง่าย ไว สะดวก',
    type: 'facebook',
    time: dayjs('2021-10-19').fromNow(),
  },
  {
    avatar: getStaticCDN('/assets/th/customer-review-profile/phatcharaphol.jpeg'),
    name: 'Phatcharaphol Saentho',
    star: 5,
    review: 'ใช้งานดีมากครับ สะดวก ปลอดภัย',
    type: 'facebook',
    time: dayjs('2021-10-18').fromNow(),
  },
  {
    avatar: getStaticCDN('/assets/th/customer-review-profile/story.jpeg'),
    name: 'สตอรี่ ผังเมือง',
    star: 5,
    review: 'ออกรถกับCarro มีโปรช่วยผ่อนรถสวยส่งถึงที่ คุยกันง่ายเชื่อถือได้100%ค่ะ',
    type: 'facebook',
    time: dayjs('2021-10-18').fromNow(),
  },
  {
    avatar: getStaticCDN('/assets/th/customer-review-profile/piyapat.jpeg'),
    name: 'Piyapat Samatkij',
    star: 5,
    review: 'บริการดี มีตัวเลือกเยอะ ขอดูได้ทุกจุด',
    type: 'facebook',
    time: dayjs('2021-10-18').fromNow(),
  },
  {
    avatar: getStaticCDN('/assets/th/customer-review-profile/gris.jpeg'),
    name: 'Gris Gam',
    star: 5,
    review: 'ได้ราคาตามที่ตั้งใจ สรุปไว แนะนำเลยค่ะ',
    type: 'facebook',
    time: dayjs('2021-10-16').fromNow(),
  },
  {
    avatar: getStaticCDN('/assets/th/customer-review-profile/mark.jpeg'),
    name: 'มาร์ค กิตตินันท์',
    star: 5,
    review: 'บริการดี บริการไว เชื่อถือได้แน่นอน',
    type: 'facebook',
    time: dayjs('2021-10-15').fromNow(),
  },
  {
    avatar: getStaticCDN('/assets/th/customer-review-profile/thanachot.jpeg'),
    name: 'Thanachot Channim',
    star: 5,
    review: 'มารับที่carro ซื้อรถH1บริการดีมากครับ',
    type: 'facebook',
    time: dayjs('2021-10-15').fromNow(),
  },
  {
    avatar: getStaticCDN('/assets/th/customer-review-profile/jaw.jpeg'),
    name: 'Jaw Insen',
    star: 5,
    review: 'บริการดี โปรโมชั่นมากมาย แนะนำค่ะ',
    type: 'facebook',
    time: dayjs('2021-10-12').fromNow(),
  },
  {
    avatar: getStaticCDN('/assets/th/customer-review-profile/saksiri.jpeg'),
    name: 'Saksiri Thadee',
    star: 5,
    review: 'ออกรถกับคุณพลอยมีโปรช่วยผ่อนรถสวยส่งถึงที่ ขอบคุณครับ',
    type: 'facebook',
    time: dayjs('2021-10-12').fromNow(),
  },
  {
    avatar: getStaticCDN('/assets/th/customer-review-profile/napaporn.jpeg'),
    name: 'Napaporn Klaijieam',
    star: 5,
    review: 'บริการดี โปรโมชั่นมากมาย แนะนำค่ะ',
    type: 'facebook',
    time: dayjs('2021-10-12').fromNow(),
  },
  {
    avatar: getStaticCDN('/assets/th/customer-review-profile/jibjib.jpeg'),
    name: 'Jib Tanyarat',
    star: 5,
    review: 'บริการดี มีโปรชั่นมากมาย เชื่อถือได้ 100% ค่ะ',
    type: 'facebook',
    time: dayjs('2021-10-11').fromNow(),
  },
  {
    avatar: getStaticCDN('/assets/th/customer-review-profile/prione.jpeg'),
    name: 'ไพรวรรณ์ เพราะจันทร์',
    star: 5,
    review: 'บริการดีมากครับส่งถึงบ้านเซลผึ้งครับ',
    type: 'facebook',
    time: dayjs('2021-10-11').fromNow(),
  },
  {
    avatar: getStaticCDN('/assets/th/customer-review-profile/natee.jpeg'),
    name: 'Natee Kaewlee Nam',
    star: 5,
    review: 'โปรโมชั่นดีๆมีอีกแล้วเชื่อถือได้ 100%',
    type: 'facebook',
    time: dayjs('2021-10-11').fromNow(),
  },
  {
    avatar: getStaticCDN('/assets/th/customer-review-profile/pumpui.jpeg'),
    name: 'Pumpui Jirapa',
    star: 5,
    review:
      'ตอนแรกไม่เชื่อว่าฝากขายรถฟรีละดีจะมีจริง จนได้มารู้จักCarro โดยเฉพาะน้องเนย ดูแลทั้งคนขายและคนซื้อดีมาก แนะนำทุกอย่าง ดูแลตั้งแต่ต้นจนจบ #ดีต่อใจCarro',
    type: 'facebook',
    time: dayjs('2021-10-11').fromNow(),
  },
  {
    avatar: getStaticCDN('/assets/th/customer-review-profile/sarisa.jpeg'),
    name: 'SaRisa KanTong',
    star: 5,
    review: 'บริการดี มีโปรโมชั่นมากมาย น่าสนใจมาก เชื่อถือได้ 100%',
    type: 'facebook',
    time: dayjs('2021-10-11').fromNow(),
  },
  {
    avatar: getStaticCDN('/assets/th/customer-review-profile/arthit.jpeg'),
    name: 'Arthit Phansahwat',
    star: 5,
    review: 'บริการดีมากครับ ชอบมากอะไรที่เราทำไม่ได้ก็มีบอกทุกอย่าง เข้าใจง่ายด้วย ชอบมากๆ',
    type: 'facebook',
    time: dayjs('2021-10-10').fromNow(),
  },
  {
    avatar: getStaticCDN('/assets/th/customer-review-profile/max.jpeg'),
    name: "บัง'แม็ก หำน้อย",
    star: 5,
    review: 'บริการดีตอบทุกปัญหาความไม่เข้าใจ',
    type: 'facebook',
    time: dayjs('2021-10-10').fromNow(),
  },
];

const CUSTOMER_REVIEW_MY: ICustomerReview[] = [
  {
    avatar: getStaticCDN('/assets/shared/user-reviews/my/ezlin-zulkarnain.webp'),
    name: 'Ezlin Zulkarnain',
    star: 5,
    review:
      'I must say my experience was outstanding! All thanks to the amazing Mr Vinc🙏🏻 He were attentive to my needs and genuine enthusiasm throughout the entire process. Kudos Vinc!',
  },
  {
    avatar: getStaticCDN('/assets/shared/user-reviews/my/sheik-imran.webp'),
    name: 'Sheik Imran',
    star: 5,
    review:
      'I had a wonderful experience purchasing a used vehicle. The process was stress free and pleasant. mytukar was very thorough and knowledgeable. He was able to find me a vehicle that suited my needs.',
  },
  {
    avatar: getStaticCDN('/assets/shared/user-reviews/my/arif-farhan.webp'),
    name: 'Arif Farhan',
    star: 5,
    review:
      'Service here very efficient and systematic! I really satisfied with Carro service. The salesperson Mr Tarmezi helped me a lot, keep following me up until I got my car 👍 VERY RECOMMENDED, If anyone want to get the same experience as me contact Mr Tarmezi ',
  },
  {
    avatar: getStaticCDN('/assets/shared/user-reviews/my/esther-arulnathan.webp'),
    name: 'Esther Arulnathan',
    star: 5,
    review:
      'First time purchasing the car, felt like a scam but after contacting with mr hadi izzudin, and at that time i felt some relieve as the place was true. great character, very helpful all the way, and great personality.',
  },
  {
    avatar: getStaticCDN('/assets/shared/user-reviews/my/liesha-sha.webp'),
    name: 'Liesha Sha',
    star: 5,
    review:
      'A faster buy car ever,recomendend for you guys to buy car here, very polite and great customer services from sherry.',
  },
  {
    avatar: getStaticCDN('/assets/shared/user-reviews/my/mohamed-aslam.webp'),
    name: 'Mohamed Aslam',
    star: 5,
    review:
      'Carro terbaikk. Sales person Andy the best that i’ve dealt with. Very professional and egaging. He keeps me updated every progress of the purchase. Kudos tu him and mytukar!',
  },
  {
    avatar: getStaticCDN('/assets/shared/user-reviews/my/nrul-msyitah.webp'),
    name: 'Nrul Msyitah',
    star: 5,
    review:
      'MyTukar provide a very good services, fast, easy process and fast car delivery. Thnks to SA Mr Kent Chong for your professional sales service and advice to me during my purchase of the used car. Thnks for the great effort in making the timely delivery of the car. I would rate 5/5',
  },
  {
    avatar: getStaticCDN('/assets/shared/user-reviews/my/hairunnissa-nadia.webp'),
    name: 'Hairunnissa Nadia',
    star: 5,
    review:
      'Easy process and quite fast. First time buying pre owned car. The car was nice, reconditioned and almost like new. Any inquiries regarding car that purchase with MyTukar can inform sales advisor. My purchase was handle by Mr. Danieal Azmi. Quite responsive and very helpful. Great and keep up the good work Mr.Danieal and MyTukar team.',
  },
  {
    avatar: getStaticCDN('/assets/shared/user-reviews/my/ahh-yong.webp'),
    name: 'Ahh Yong',
    star: 5,
    review:
      'Had a fantastic experience buying car from MyTukar Penang juru auto city, Mr Steve is great to work with. It was a one-stop shop for all of my questions and made car buying a worry-free experience. Highly recommend going to Mr Steve for his service',
  },
  {
    avatar: getStaticCDN('/assets/shared/user-reviews/my/manesh-em-kay.webp'),
    name: 'Manesh Em Kay',
    star: 5,
    review:
      'Fantastic, person in charge mohd. hadi from puchong outlet was awesome...speedy service..good communication..takes the extra step for customer satisfaction and his product knowledge magnificent. car was ready in time including loan processing. salute',
  },
  {
    avatar: getStaticCDN('/assets/shared/user-reviews/my/norbaizura-mohd-sukali.webp'),
    name: 'Norbaizura Mohd Sukali',
    star: 5,
    review:
      'Had a fantastic experience buying my vehicle from MyTukar. Mr Azrul Azhary is great to work with. It was a one-stop shop for all of my questions and made car buying a worry-free experience. Highly recommend going to Mr Azrul for his service',
  },
  {
    avatar: getStaticCDN('/assets/shared/user-reviews/my/rosa-harvey-canal.webp'),
    name: 'Rosa Harvey Canal',
    star: 5,
    review:
      'I found MyTukar to be a most professional company in all respects. I take this opportunity to thank Calvert and Vincent for an excellent car buying experience.',
  },
  {
    avatar: getStaticCDN('/assets/shared/user-reviews/my/kamarul-zaman.webp'),
    name: 'Kamarul Zaman',
    star: 5,
    review:
      'Very excellent service from SA Sharifah mytukar Plentong. Approval within 1 day. All process to deliver only 7 days. Delivered the car with excellent condition. Price very reasonable since we’ve make survey with other dealer. Mytukar offer the lowest price with good cond and come with 1 year warranty. Recommended with 10+ star service.',
  },
  {
    avatar: getStaticCDN('/assets/shared/user-reviews/my/chee-soong-leong.webp'),
    name: 'Chee Soong Leong',
    star: 5,
    review:
      'Mytukar provide very good service , fast and easy process , fast car delivery and service center provide well checking for the car overall. Mr Kent Chong from Puchong South very professional and helpful, and would rate 5 star .',
  },
  {
    avatar: getStaticCDN('/assets/shared/user-reviews/my/cammie-chung.webp'),
    name: 'Cammie Chung',
    star: 5,
    review:
      'Great service and customer service at Carro after a horrible experience at Carsome. Wasted an hour of my time without anything being done. MyTukar only took 5 minutes to attend to me and got everything sorted.',
  },
  {
    avatar: getStaticCDN('/assets/shared/user-reviews/my/intan-syazliza-iskandar.webp'),
    name: 'Intan Syazliza Iskandar',
    star: 5,
    review:
      'Carro is the best platform for those who looking for many choices to buy cars, because here the best customer service as the first choice we can get. Many thanks to SA Mr Kent Chong for providing us a good experience with my Tukar…in which I would like to give 10/10 as for his professionalism and fulfill our expectation.',
  },
  {
    avatar: getStaticCDN('/assets/shared/user-reviews/my/arif-alhafiz.webp'),
    name: 'Arif Alhafiz',
    star: 5,
    review:
      'Good buying experience at MyTukar. Good service and very responsive Sales Agent. Got a car for only 3 days after booking. Thank you en Zul Hafiz MyTukar Puchong.',
  },
  {
    avatar: getStaticCDN('/assets/shared/user-reviews/my/suci-hurayrah.webp'),
    name: 'Suci Hurayrah',
    star: 5,
    review:
      'Great service from Abu Hurayrah .. Only needed 3 days to deliver at my door step... Great recommendation from me',
  },
  {
    avatar: getStaticCDN('/assets/shared/user-reviews/my/atierah-samil.webp'),
    name: 'Atierah Samil',
    star: 5,
    review:
      'Hi, recently i received my car PROTON SAGA in very good condition very like new, and of course the staff of MyTukar Haziq, veryy kind and good in communication, give customer comfort and fast in progress. Very recommend him Haziq from MyTukar Seremban.',
  },
  {
    avatar: getStaticCDN('/assets/shared/user-reviews/my/lagaMs-beta.webp'),
    name: 'LagaMs Beta',
    star: 5,
    review:
      'At first I was skeptical to buy used car. Then Carro come into a picture which gives you an assurance that the quality of the car that they have is free from flood, major engine problem and the quality and image is like 95% looks like new car. I am very impressive. Not to forget, kent chong really helps me through out my buying process from A to Z. Really saves A LOT of my times! Thank you!',
  },
  {
    avatar: getStaticCDN('/assets/shared/user-reviews/my/alip-yohya.webp'),
    name: 'Alip Yohya',
    star: 5,
    review:
      'Shout out to Carro for such a great service. The process was easy and the delivery from Melaka to Terengganu was fast. A big thanks to Shem from mytukar Melaka for assisting me with the purchase.',
  },
  {
    avatar: getStaticCDN('/assets/shared/user-reviews/my/chua-chee-yong.webp'),
    name: 'Chua Chee Yong',
    star: 5,
    review:
      'I was attended with all the information required on purchasing MYVI. First car ownership without breaking more penny definitely will be with MyTukar. Keep up the good work',
  },
  {
    avatar: getStaticCDN('/assets/shared/user-reviews/my/muga-bala.webp'),
    name: 'Muga Bala',
    star: 5,
    review: 'Thanks Carro Hugo from Auto City,Juru!! He really help me a lot to get my loan approval!! Powerful',
  },
  // {
  //   avatar: getStaticCDN('/assets/shared/user-reviews/my/dennis-goon-siew-whye.webp'),
  //   name: 'Dennis Goon Siew Whye',
  //   star: 5,
  //   review:
  //     'Service is excellent, procedure is very fast, efficient, will recommend to friends and family, all thanks to Ken Wong',
  // },
  {
    avatar: getStaticCDN('/assets/shared/user-reviews/my/aziah-anuar.webp'),
    name: 'Aziah Anuar',
    star: 5,
    review:
      'Great seller to deal with speedy replies and also keep updating me about the car . Helpful & nice dealing with Mr Andy. MyTukar is a good option to buy & sell your car. FULLY RECOMMENDED!',
  },
  {
    avatar: getStaticCDN('/assets/shared/user-reviews/my/aizalian-ghazali.webp'),
    name: 'Aizalian Ghazali',
    star: 5,
    review: `Alhamdulillah smooth and fast dealing. Very recommended for you guys who is still looking for "new" secondhand car. Reasonable price for trade in car. Very great helpful nice polite and pretty personal sale i.e Pn Sharifah from Mytukar Plentong's branch. Thank you for everything`,
  },
  {
    avatar: getStaticCDN('/assets/shared/user-reviews/my/no_a-shaera.webp'),
    name: "No'a Shaera",
    star: 5,
    review: 'Very good experience with mytukar.. everything well smoothly and fast, highly recommended',
  },
  {
    avatar: getStaticCDN('/assets/shared/user-reviews/my/zuseng-tang.webp'),
    name: 'Zuseng Tang',
    star: 5,
    review: 'Nice service and quality from Mytukar',
  },
];

const CUSTOMER_REVIEW_TW: ICustomerReview[] = [
  {
    avatar: getStaticCDN('/assets/tw/customer-review-profile/first-review.png'),
    name: '朱炎輝',
    star: 5,
    review: '我們的業務徐*庭先生很熱誠，效率也高，這是一次很開心的買車經驗，大推～',
  },
  {
    avatar: getStaticCDN('/assets/tw/customer-review-profile/second-review.png'),
    name: 'Tin_y',
    star: 5,
    review: '業務很熱情跟我介紹，也跟我分享很多汽車相關常識，值得推薦。',
  },
  {
    avatar: getStaticCDN('/assets/tw/customer-review-profile/third-review.png'),
    name: '陳亮霖',
    star: 5,
    review: '整體銷售服務氛圍很好，安心購車無壓力',
  },
  {
    avatar: getStaticCDN('/assets/tw/customer-review-profile/fourth-review.png'),
    name: 'Yu-Ting Kao',
    star: 5,
    review: 'Eddy親切，回應問題很快，交車流程也很有效率👍這次跟Carro交易的體驗滿分💯',
  },
  {
    avatar: getStaticCDN('/assets/tw/customer-review-profile/fifth-review.png'),
    name: 'Alan Chang',
    star: 5,
    review: '業務很親切 服務很好 值得推薦來這裡買車',
  },
  {
    avatar: getStaticCDN('/assets/tw/customer-review-profile/sixth-review.png'),
    name: 'Free man 24',
    star: 5,
    review: '服務人員專業解說，態度親切，讓人輕鬆愉悅的賞車',
  },
  {
    avatar: getStaticCDN('/assets/tw/customer-review-profile/seventh-review.png'),
    name: 'Chiachun Hung',
    star: 5,
    review: '專業、耐心、讓你沒有壓力的賞車和考慮。大推',
  },
  {
    avatar: getStaticCDN('/assets/tw/customer-review-profile/eighth-review.png'),
    name: '孫世華',
    star: 5,
    review: '服務人員態度好，解說仔細，細節介紹清楚',
  },
  {
    avatar: getStaticCDN('/assets/tw/customer-review-profile/ninth-review.png'),
    name: '李崧初',
    star: 5,
    review: '業務人很好很親切，服務很棒值得推薦！',
  },
];

const CUSTOMER_REVIEW_HK: ICustomerReview[] = [
  {
    avatar: getStaticCDN('/assets/shared/user-reviews/sg/bruno-low.webp'),
    name: 'Bruno Low',
    star: 5,
    review:
      'I came to Carro to initially sell my car. the price excellent, after discussing online with the staff, i have shortlisted few cars for trade in. Jonathan lee was helping us throughout the process providing good advice and never push for the most costly option. he made us try all the cars and finally got the one that fit our family needs. will definitely recommend Jonathan lee who did a good job',
  },
  {
    avatar: getStaticCDN('/assets/shared/user-reviews/sg/raymond-chen.webp'),
    name: 'Raymond Chen',
    star: 5,
    review:
      'Jonathan is definitely the best person to go to if you are looking to purchase a car. Positive and cheerful attitude sure lighten up the stress when choosing my 1st ride. He followed through the whole process of my purchase with constant updates of information. True gem of Carro, will definitely spread the words of his awesome service!!! Thanks bro!!',
  },
  {
    avatar: getStaticCDN('/assets/shared/user-reviews/sg/世坤馮.webp'),
    name: '世坤馮',
    star: 5,
    review:
      'We all know how dark and dirty the 2nd hand car dealer industry is, so i went in to Carro being very skeptical of the car i was interested in. I was assigned to Jon, who was really honest, frank and also professional. He was really accomodating with my requests, but also really frank when they are too overboard. While i was away during my business trip, Jon constantly gave me updates. The day before the collection, jon even reminded me to ensure that i increased my daily spending limit to minimise any hiccups during the collection itself. Even after the collection, he has been super helpful in getting me settled in to my new ride. Id recommend jon and carro to anyone who is looking for a seamless, friendly and honest service!',
  },
  {
    avatar: getStaticCDN('/assets/shared/user-reviews/sg/timothy-paul-xie.webp'),
    name: 'Timothy Paul Xie',
    star: 5,
    review:
      'Dominic was an absolute pleasure to deal with. He went the extra mile in helping me with getting a great deal for the car, and was extremely insightful and meticulous throughout the whole deal process. It was a smooth transaction and since having the car for 7 months I have not had any issues. Should I consider changing my vehicle in the future - Carro, together with Dominic, would most certainly be my choice! 😁',
  },
  {
    avatar: getStaticCDN('/assets/shared/user-reviews/sg/ryan-marc.webp'),
    name: 'Ryan Marc',
    star: 5,
    review:
      "sold my car at Carro recently, very professional and friendly staff. fast, convenient, good price. definitely will recommend / head back to them if I'm selling or buying. good job Carro 👍 (staff to thank: Cody - main person I dealt with & Raymond)",
  },
  {
    avatar: getStaticCDN('/assets/shared/user-reviews/sg/calvin-ennovyevoli.webp'),
    name: 'Calvin Ennovyevoli',
    star: 5,
    review:
      'This is my first time purchasing car from Carro..i enjoyed the whole process, no hidden charges, everything explained nicely from Mr Jonathan, which was the sales liaising with me. Service was excellent, due to my work schedule, he was able to accommodate to my timing and location for handover my old car and takeover my new car..very prompt in replying my enquiries even after his working hours. After sales service was excellent too! Definitely will look for him again if i want to change car👍👍',
  },
  {
    avatar: getStaticCDN('/assets/shared/user-reviews/sg/edmund-soh.webp'),
    name: 'Edmund Soh',
    star: 5,
    review:
      "Dominic is knowledgeable & patient and takes time to answer all our questions ! Feels safe & comfortable dealing with Carro & Dominic especially when the 2nd hand car market has quite a bad reputation. Aftersales service didn't disappoint too !  Dominic is open to help with any questions or issues we have about the car post sales  Go to Carro & look for Dominic ! No regrets to deal with them ",
  },
  {
    avatar: getStaticCDN('/assets/shared/user-reviews/sg/james-chia.webp'),
    name: 'James Chia',
    star: 5,
    review:
      'Great service provided by Jonathan. He was very patient and answered every question that was asked. Extremely easy person to talk to. Felt like an old time friend yet still maintained that professionalism. Went the extra mile to handover the car in double quick time. Even went back to handover on his off day! 10/10 will recommend! 😇',
  },
  {
    avatar: getStaticCDN('/assets/shared/user-reviews/sg/louies-tan.webp'),
    name: 'Louies Tan',
    star: 5,
    review:
      'Purchased a car from a sales rep named Jonathan... Received an outstanding service from him, ranging from being attentive to your needs, to meticulously taking note of the issues/requests, Jon had totally aced all aspects as an experience salesperson. In fact, on the day of collection, he even took effort to ensure that the vehicle is sanitised properly (bcos I have a 2-yr old kid to pick up after that)! My recommendation - definitely worthy of a visit to view your preferred vehicles with him and have a honest and upfront discussion with him. No hidden agendas, no ballyhoos.... Jonathan is da man!',
  },
  {
    avatar: getStaticCDN('/assets/shared/user-reviews/sg/kelvin-ang.webp'),
    name: 'Kelvin Ang',
    star: 5,
    review:
      "Truly enjoyed the process of purchasing a second hand vehicle from Carro. It's definitely refreshing to step away from the typical, traditional second hand car dealers in Singapore, the ones with the hidden charges, in-house loans for the max term avail., etc. It feels great talking to someone who is informative, direct and most importantly open. To me, Carro made buying a second hand car frustration free and driving away feeling assured. Wish more dealers are like this. Thank you, Jonathan (Lee).",
  },
  {
    avatar: getStaticCDN('/assets/shared/user-reviews/sg/yeeling-yeong.webp'),
    name: 'Yee Ling Yeong',
    star: 5,
    review:
      'Was introduced by a friend to contact Carro and we had a good and hassle free experience with him. No hard selling, very responsive to our queries, before, during and after transaction. Would recommend Carro and look for Jonathan!',
  },
  {
    avatar: getStaticCDN('/assets/shared/user-reviews/sg/jiangbin-sun.webp'),
    name: 'Jiangbin Sun',
    star: 5,
    review:
      'Definitely recommend! Carro is a quite good one stop shop for first time buyers like us. Jonathan really helped us a lot from all aspects - introducing the car, sharing the whole process, recommending on insurance and loan. He is knowledgeable and willing to help always. Great experience!',
  },
  {
    avatar: getStaticCDN('/assets/shared/user-reviews/sg/jean-ong.webp'),
    name: 'Jean Ong',
    star: 5,
    review:
      'Compliment for Jonathan. When we first visited Carro, we weren’t expect it would become the best car dealer company we have ever visited. We were warmly welcomed by Jonathan (sales representative), he was young and we thought he was a fresh grad. Little did we expect his vast knowledge about car models and car sales immediately drew our attention. He shared that he has been in car sales Industry for over 12 years. Jonathan was not like any sales we met before, his humour, helpful and casual personality made us felt like friends. He was sincere and shared his knowledge about car models we inquired patiently. He was truthful and no hard-sell, no pressure, he addressed all our concerns effortlessly. He made car transaction so simplified and fuss free. Jonathan has delivered a complete seamless transaction we could ever imagined, he direct offered us the best deal on purchase and trade-in without needing wasting time on draggy bargaining. He offered us new car accessories (customised car mat, car cam front and back, original car plate frame, etc) which added more perks to our purchase. From trading in, purchasing, loan, insurance, retaining car plate nbr, all paperwork, and ensuring finest condition of the car before handover. Every detail was well taken care by him. In addition, Jonathan will continue attending to all our queries swiftly after deal clinched (giving us assurance and a peace of mind). We were glad we made the right choice to Carro and meeting such exemplary sales - Jonathan, who has delivered a truly remarkable car purchasing experience to us. We highly recommend anyone who plans to purchase car to look for Jonathan @ Carro 👍🏻🤩 Reference: SMW5193T BMW 118i',
  },
  {
    avatar: getStaticCDN('/assets/shared/user-reviews/sg/vick-vick.webp'),
    name: 'Vick Vick',
    star: 5,
    review:
      'Jonathan is very helpful in the way he explaining the vehicle to me. Im very grateful for his assistance over here at carro. Together with Magdalene, she assisted in the rest of the admin works and im pleased to be served by them',
  },
  {
    avatar: getStaticCDN('/assets/shared/user-reviews/sg/herman-hidayatullah.webp'),
    name: 'Herman Hidayatullah',
    star: 5,
    review:
      'Had a wonderful experience with Cody today. Simple, helpful and transparent. Just what i needed when letting go the car. Thanks again Cody for the great job!',
  },
  {
    avatar: getStaticCDN('/assets/shared/user-reviews/sg/diana-tan.webp'),
    name: 'Diana Tan',
    star: 5,
    review:
      'Visited Carro with my brother for his first car and was served by Jonathan. He showed great patience while addressing to our many queries. We eventually managed to find a suitable ride with the excellent recommendations and advice by Jonathan. Definitely will go back to him for our future car purchases and also recommend to our family and friends!',
  },
  {
    avatar: getStaticCDN('/assets/shared/user-reviews/sg/desmond-low.webp'),
    name: 'Desmond Low',
    star: 5,
    review:
      'We were attended to by Jonathan. He was very professional and tried his best to answer all our queries and made sure to meet our requirements and needs as first time buyers. He was professional and we never felt that he was just transacting a sale, but rather, giving us invaluable advice and suggestions. He made additional effort to ensure that our purchase experiences were a positive one. Thank you Jonathan!',
  },
  {
    avatar: getStaticCDN('/assets/shared/user-reviews/sg/charles-chia.webp'),
    name: 'Charles Chia',
    star: 5,
    review:
      'Jonathan has been a great help in assisting us in getting our first car. Very honest, very knowledgeable and very candid. We are very happy with the purchase and also all the services rendered. Great service from Jonathan, great experience with Carro! 🙂',
  },
  {
    avatar: getStaticCDN('/assets/shared/user-reviews/sg/arran-tan.webp'),
    name: 'Arran Tan',
    star: 5,
    review:
      'Was interested in the BMW 118i and was served Karen Goh on 24 Jul 2022. She approached with a friendly attitude and had much eagerness to assist our demands and answered our questions professionally. A joy to have served by her. Thank you Karen!',
  },
  {
    avatar: getStaticCDN('/assets/shared/user-reviews/sg/deniel-ong.webp'),
    name: 'Deniel Ong',
    star: 5,
    review:
      'Top service, right from the admin who contacted me and arranged for an appointment, to the salesperson Jonathan who served us with sincerity. No hidden cost and even going the extra mile to resolve any potential hiccups so as to ensure a hassle free handover. Highly recommend Carro and Jonathan to all. Thanks！',
  },
  {
    avatar: getStaticCDN('/assets/shared/user-reviews/sg/raymund-francis.webp'),
    name: 'Raymund Francis',
    star: 5,
    review:
      "I am a first-time client of Carro. I was planning to trade in my car. I was offered a 'decent' amount at the recent Car Show 2022 but was later told that it was an overtrade thing. Other car dealers offered me $20k less. However, one car dealer advised me to try Carro for a better quote. I did and, boy!, was I pleasantly surprised. I was offered much more than any other dealer, subject to a physical check. The check by Dixon Lee, confirmed my car's good condition, and I was given the promised amount without any fuss. I strongly recommend Carro! I also commend Carro's ambassador, Dixon Lee, for a job well done!",
  },
  {
    avatar: getStaticCDN('/assets/shared/user-reviews/sg/jake-tan.webp'),
    name: 'Jake Tan',
    star: 5,
    review:
      'Just had my first experience of purchasing a car experience at Carro with Jonathan! Jonathan was an absolute pleasure to deal with. He very helpful and went the extra mile to make sure I was well sorted and informed. Thank you for the excellent service and help provided through the extremely quick and efficient process to ownership. Very happy buyer and 10/10 will come back to Carro because of Jonathan. 🤝',
  },
  {
    avatar: getStaticCDN('/assets/shared/user-reviews/sg/huiLing-ng.webp'),
    name: 'HuiLing Ng',
    star: 5,
    review:
      'With Carro Singapore, sale of car is no longer a hassle. No gimmicks, no hidden extra charges. However, please take note there is a cost ($30) to be borne by the seller if you choose to have your existing car collected from your home carpark. This is reasonable since it has to be mutually agreed between the seller and Carro Singapore and will be stated clearly in the S&P agreement. After submitting your car ownership details to Carro Singapore, their staff will come back to you with a quoted amount (within 24 hours) for your consideration on whether you would like to proceed with viewing. The quoted amount is subjected to confirmation upon their staff’s assessment on the actual condition of the car after viewing. You would then have a gauge of where you are. If you are satisfied with the quoted amount confirmed by their staff upon viewing, their staff will prepare a S&P agreement for you to sign on the spot. Depending on your availability and agreement with Carro Singapore, the handover date is flexible. To conclude this review, I would say that my entire experience with Carro Singapore is excellent. The transaction was a breeze for first timers like us selling on our own. Dixon (the sales coordinator assigned by Carro) and Shaharul (the handover coordinator assigned by Carro) are very helpful, patient and nice people. Well done, Carro Singapore! 👍🏻🥳😊',
  },
  {
    avatar: getStaticCDN('/assets/shared/user-reviews/sg/leo-koh.webp'),
    name: 'Leo Koh',
    star: 5,
    review:
      'We came across Carro while researching on where to purchase a car and indeed they stood true to the many splendid reviews with a high level of professionalism and impeccable service. My wife and I had a great experience with Jonathan, he was very friendly and helpful, and prepared all the necessary paperwork and transactions promptly. It was really a fuss-free experience purchasing from Carro. Will definitely recommend Carro!',
  },
];

export const CUSTOMER_REVIEW = {
  sg: CUSTOMER_REVIEW_SG,
  id: CUSTOMER_REVIEW_ID,
  th: CUSTOMER_REVIEW_TH,
  my: CUSTOMER_REVIEW_MY,
  jp: CUSTOMER_REVIEW_TW,
  hk: CUSTOMER_REVIEW_HK,
};

interface footerDesc {
  preText: string;
  anchor: {
    text: string;
    link: string;
  };
  postText: string;
}

const CUSTOMER_REVIEW_FOOTER: { [key: string]: footerDesc } = {
  sg: {
    preText: 'sellCar.customerReview.footerDesc.sg.preText',
    anchor: {
      text: 'sellCar.customerReview.footerDesc.sg.anchorText',
      link: `${ENV_URL.sg}/blog/category/carro-news`,
    },
    postText: 'sellCar.customerReview.footerDesc.sg.postText',
  },
  my: {
    preText: 'sellCar.customerReview.footerDesc.my.preText',
    anchor: {
      text: 'sellCar.customerReview.footerDesc.my.anchorText',
      link: `${NEXT_PUBLIC_WEB_CR}/my/blog/category/carro-news`,
    },
    postText: 'sellCar.customerReview.footerDesc.my.postText',
  },
  id: {
    preText: 'sellCar.customerReview.footerDesc.id.preText',
    anchor: {
      text: 'sellCar.customerReview.footerDesc.id.anchorText',
      link: `${ENV_URL.id}/blog/category/berita/`,
    },
    postText: 'sellCar.customerReview.footerDesc.id.postText',
  },
  th: {
    preText: 'sellCar.customerReview.footerDesc.th.preText',
    anchor: {
      text: 'sellCar.customerReview.footerDesc.th.anchorText',
      link: `${ENV_URL.th}/blog/category/carro-news`,
    },
    postText: 'sellCar.customerReview.footerDesc.th.postText',
  },
  jp: {
    preText: 'sellCar.customerReview.footerDesc.tw.preText',
    anchor: {
      text: 'sellCar.customerReview.footerDesc.tw.anchorText',
      link: `${ENV_URL.tw}/blog/category/carro-news`,
    },
    postText: 'sellCar.customerReview.footerDesc.tw.postText',
  },
  hk: {
    preText: 'sellCar.customerReview.footerDesc.hk.preText',
    anchor: {
      text: 'sellCar.customerReview.footerDesc.hk.anchorText',
      link: `${NEXT_PUBLIC_WEB_CR}/my/blog`,
    },
    postText: 'sellCar.customerReview.footerDesc.hk.postText',
  },
};

const getHeaderByCountry = (country: string) => ({
  title: `sellCar.customerReview.title.${country}`,
  description: `sellCar.customerReview.description`,
});

const CUSTOMER_REVIEW_CONTENT: { [key: string]: ICustomerReviewContent } = {
  sg: {
    header: getHeaderByCountry('sg'),
    customerReviews: CUSTOMER_REVIEW.sg,
    footer: CUSTOMER_REVIEW_FOOTER.sg,
  },
  id: {
    header: getHeaderByCountry('id'),
    customerReviews: CUSTOMER_REVIEW.id,
    footer: CUSTOMER_REVIEW_FOOTER.id,
  },
  th: {
    header: getHeaderByCountry('th'),
    customerReviews: CUSTOMER_REVIEW.th,
    footer: CUSTOMER_REVIEW_FOOTER.th,
  },
  my: {
    header: getHeaderByCountry('my'),
    customerReviews: CUSTOMER_REVIEW.my,
    footer: CUSTOMER_REVIEW_FOOTER.my || null,
  },
  jp: {
    header: getHeaderByCountry('jp'),
    customerReviews: CUSTOMER_REVIEW.jp,
    footer: CUSTOMER_REVIEW_FOOTER.jp || null,
  },
  hk: {
    header: getHeaderByCountry('hk'),
    customerReviews: CUSTOMER_REVIEW.hk,
    footer: CUSTOMER_REVIEW_FOOTER.hk || null,
  },
};

// TODO_CCPO

export default CUSTOMER_REVIEW_CONTENT;
